import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { withRouter } from "react-router-dom";
import i18n from "i18next";

const AuthorName = (props) => {
    let name = props.author.lastname + ', ' + props.author.firstname;
    return name;
}

const BlockAuthors = inject("stores") (
    observer (
        class BlockAuthors extends Component {

            constructor(props) {
                super(props);
                this.props = props;

                this.storeUi = this.props.stores.storeUi;
                this.storeBooks = this.props.stores.storeBooks;
            }

            handleOnClick = (e, firstname, lastname) => {
                this.storeUi.setActiveMainNav(2);
                this.storeUi.setPageView('page', 1);

                let id = firstname + ' ' + lastname;
                this.storeUi.setCurrentAuthor(id);
                //this.storeBooks.getBooksForAuthor(this.storeUi.page_view, id, firstname, lastname);
                //this.storeUi.getBookCountForAuthor('SHOP_SB', id);
                this.storeUi.setActiveCategory('');
                this.storeUi.setActiveProductGroup('');
                this.storeUi.setNavMobile(false);

                let url = "/author/" + id;
                this.props.history.push(url);
            }
 
            render() {
                const activeClass = this.storeUi.app_interface.current_author !== '' ? ' --active' : '' 

                return (
                    <div className="block block--sidebar block--authors">
                        {!this.props.clean &&
                            <div className="block__block-title">
                                {i18n.t("block.authors_sb.header")}
                            </div> 
                        }
                        <ul className={"block__block-list" + activeClass}>
                            {this.storeBooks.authorsList.map((author, i) => ( 
                                <React.Fragment key={i}>
                                    {author.is_sb === 1 &&
                                        <li key={i} onClick={(e)=>this.handleOnClick(e, author.firstname, author.lastname)}>
                                            <span className={"block--categories__cat notranslate" + (author.id == this.storeUi.app_interface.current_author ? ' --active' : '')}>
                                                <AuthorName 
                                                    author = {author}
                                                />
                                            </span>
                                        </li>
                                    }
                                </React.Fragment>
                            ))}
                        </ul>
                    </div>
                )
            }
        }
    )
)

export default withRouter(BlockAuthors);

