import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { Link } from 'react-router-dom';
import i18n from "i18next";
import { withTranslation } from 'react-i18next';
import { withRouter } from "react-router-dom";
 
const NavigationFooter = inject("stores") (
    observer (
        class NavigationFooter extends Component {

            constructor(props) {
                super(props);
                this.props = props;

                this.storeGeneral = this.props.stores.storeGeneral;
                this.storeUi = this.props.stores.storeUi;
                this.storeUser = this.props.stores.storeUser;
            }

            handleNavClick = (id, path) => {
                this.storeUi.setActiveMainNav(0);
                this.storeUi.setActiveSubNav(0);

                switch(id) {
                    case 1:
                        this.storeGeneral.setShop("SHOP_SB");
                        this.storeGeneral.setActiveShop("SHOP_SB");
                        break;
                    case 2:
                        this.storeGeneral.setShop("SHOP_SB");
                        this.storeGeneral.setActiveShop("SHOP_SB");
                        break;
                    case 3:
                        this.storeGeneral.setShop("SHOP_ALL");
                        this.storeGeneral.setActiveShop("SHOP_ALL");
                        break;
                    default:
                        this.storeGeneral.setShop("SHOP_SB");
                        this.storeGeneral.setActiveShop("SHOP_SB");
                }

                if (this.storeGeneral.shop === 'SHOP_SB') {
                    id = 2;
                 } else {
                    id = 3;
                 }
                this.storeUi.setActiveToggleNav(id);

                this.props.history.push(path);
            }

            handleSubNavClick = (path) => {
                window.scrollTo({
                    top: 0,
                    behavior: "smooth"
                });
                this.props.history.push(path);
            }

            render() {
                return (
                    <div className="footer__navigation">
                        <ul className="navigation--footer">
                            <li className="navigation--footer__column">
                                {this.storeUi.checkFeature('SHOP_ALL') &&
                                <React.Fragment>
                                    <span className="navigation--footer__title">Catalogus</span>
                                    <ul className="navigation--footer__list">
                                        {this.storeUi.toggle_nav.map((item, i) => (
                                            <li 
                                                key = {i}
                                                className = "navigation--footer__listitem --link" 
                                                onClick = {() => this.handleNavClick(item.id, item.path)}
                                                dangerouslySetInnerHTML={{ __html: i18n.t("navigation." + item.name)}}
                                            />
                                        ))}
                                    </ul>
                                </React.Fragment>
                                }
                                
                                <span className="navigation--footer__title">Succesboeken.nl</span>
                                <ul className="navigation--footer__list">
                                    {this.storeGeneral.shop === 'SHOP_SB' &&
                                        <React.Fragment>
                                            <li className="navigation--footer__listitem"><Link to="/our-publications">Boeken eigen uitgeverij</Link></li>
                                            <li className="navigation--footer__listitem"><Link to="/new-books">Nieuw toegevoegd</Link></li>
                                            <li className="navigation--footer__listitem"><Link to="/ebooks">Gratis e-boeken</Link></li>
                                            <li className="navigation--footer__listitem"><Link to="/events"><span dangerouslySetInnerHTML={{ __html: i18n.t("navigation.events")}}></span></Link></li>
                                            <li className="navigation--footer__listitem"><Link to="/press">Media/Pers</Link></li>
                                            <li className="navigation--footer__listitem"><Link to="/videos">Video's</Link></li>
                                        </React.Fragment>
                                    }
                                    <li className="navigation--footer__listitem"><Link to="/terms-conditions">Algemene Voorwaarden + retouren</Link></li>
                                    <li className="navigation--footer__listitem"><Link to="/privacy-policy">Privacy Policy</Link></li>
                                    <li className="navigation--footer__listitem"><Link to="/disclaimer">Disclaimer</Link></li>
                                    <li className="navigation--footer__listitem"><Link to="/sitemap"><span>{i18n.t("navigation.sitemap")}</span></Link></li>
                                </ul>

                                
                            </li>
                            <li className="navigation--footer__column">
                                <span className="navigation--footer__title">De uitgeverij</span>
                                <ul className="navigation--footer__list">
                                    <li className="navigation--footer__listitem"><Link to="/our-publications">Onze uitgeverij Succesboeken.nl</Link></li>
                                    <li className="navigation--footer__listitem"><Link to="/mission">Missie & Passie</Link></li>
                                    {this.storeUser.user_data.is_loggedin && this.storeUser.user_data.partner_code !== '' &&
                                        <li className="navigation--footer__listitem"><Link to="/partner"><span dangerouslySetInnerHTML={{ __html: i18n.t("navigation.partner")}}></span></Link></li>
                                    }
                                    {(!this.storeUser.user_data.is_loggedin || (this.storeUser.user_data.is_loggedin && this.storeUser.user_data.partner_code === '')) &&
                                        <li className="navigation--footer__listitem"><Link to="/partners"><span dangerouslySetInnerHTML={{ __html: i18n.t("navigation.partners")}}></span></Link></li>
                                    }
                                    <li className="navigation--footer__listitem"><Link to="/business">Zakelijk - Boekhandel</Link></li>
                                    <li className="navigation--footer__listitem"><Link to="/own-book">Een eigen boek schrijven</Link></li>
                                    <li className="navigation--footer__listitem"><Link to="/jobs"><span>{i18n.t("navigation.jobs")}</span></Link></li>
                                </ul>
                            </li>
                            <li className="navigation--footer__column">
                                <div>
                                {this.storeUser.user_data.is_loggedin &&
                                    <React.Fragment>
                                        <span className="navigation--footer__title">Mijn account</span>
                                        <ul className="navigation--footer__list">
                                            <li 
                                                className="navigation--footer__listitem --link"
                                                onClick = {() => this.handleSubNavClick("/account")}>
                                                    Mijn gegevens
                                            </li>
                                            <li 
                                                className = "navigation--footer__listitem --link" 
                                                onClick = {() => this.handleSubNavClick("/account/orders")}>
                                                    Mijn bestellingen
                                            </li>
                                        </ul>
                                    </React.Fragment>
                                }
                                <span className="navigation--footer__title">Contact</span>
                                <ul className="navigation--footer__list">
                                    <li className="navigation--footer__listitem"><Link to="/remarks">Opmerkingen/vragen?</Link></li>
                                    <li className="navigation--footer__listitem"><Link to="/address">Adres & bedrijfsgegevens</Link></li>
                                </ul>
                                </div>
                            </li>
                        </ul>
                    </div>
                )
            }
        }
    )
)

export default withRouter(withTranslation()(NavigationFooter));
