import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { Switch, Route } from 'react-router-dom';
import ReactGA from 'react-ga';
import i18n from "i18next";
import { runInAction } from "mobx";
import { withRouter } from "react-router-dom";
import * as rdd from "react-device-detect";
import CacheBuster from './cacheBuster';
import { withCookies } from "react-cookie"

import './assets/scss/succesboeken.scss';

import Loader from './elements/loader';

import Routes from './routes';

import DialogContent from './components/dialogs/dialog-content';
import DialogCookies from './components/dialogs/dialog-cookies';
import DialogCompareShoppingCarts from './components/dialogs/dialog-compare-shoppingcarts';
import DialogCompareWishLists from './components/dialogs/dialog-compare-wishlists';
import DialogConfirmation from './components/dialogs/dialog-confirmation';
import DialogConfirmationWishList from './components/dialogs/dialog-confirmation-wishlist';
import DialogError from './components/dialogs/dialog-error';
import DialogFeedbackForm from './components/dialogs/dialog-feedback-form';
import DialogFeedbackForm20 from './components/dialogs/dialog-feedback-form-20';
import DialogNewsletter from './components/dialogs/dialog-newsletter';
import DialogPayment from './components/dialogs/dialog-payment';
import DialogNotification from './components/dialogs/dialog-notification';
import DialogVideo from './components/dialogs/dialog-video';

import Header from './components/grid/header';
import Footer from './components/grid/footer';

const DEFAULT_CONFIG = {
    trackingId: 'UA-4967097-1',
    debug: false,
    gaOptions: {
        cookieDomain: 'none'
    }
};

const App = inject("stores") (
    observer (
        class App extends Component {

            constructor(props) {
                super(props);
                this.props = props;

                this.storeAuth = this.props.stores.storeAuth;
                this.storeGeneral = this.props.stores.storeGeneral;
                this.storeUi = this.props.stores.storeUi;
                this.storeForms = this.props.stores.storeForms;
                this.storeOrder = this.props.stores.storeOrder;
                this.storeShipping = this.props.stores.storeShipping;
                this.storeUser = this.props.stores.storeUser;
                this.storeWishlist = this.props.stores.storeWishlist;

                this.state = {
                    reactGaInitialised: false,
                    configs: [DEFAULT_CONFIG],
                    cookie_visible: false
                };

                //this.wrapperRef = createRef();

                this.props.cookies.remove("_ga");
                this.props.cookies.remove("_gid");
                this.props.cookies.remove("_gat");

                this.initReactGA();
            }

            async componentDidMount() {
                let activeShop;

                try {
                    const returnId = await this.storeUser.getUserDataFromLS();
                    runInAction(() => {                       
                        this.storeOrder.retrieveShoppingCart(returnId, 'init');
                        this.storeWishlist.retrieveWishlist(returnId);
                        if (returnId === 0) {
                            //this.storeOrder.resetCartUser();
                        }
                    })
                } catch (error) {
                    runInAction(() => {
                        this.state = "error"
                    })
                }

                //SbC default segment always SHOP_SB
                this.storeGeneral.setActiveShop('SHOP_SB');

                //SbC default segment depending on last visit
                // if (this.storeUi.checkFeature('SHOP_ALL')) {
                //     activeShop = await this.storeGeneral.getActiveShop();

                //     if (this.storeGeneral.activeShop === 'SHOP_SB') {
                //         this.storeUi.setActiveToggleNav(2);
                //     } else {
                //         this.storeUi.setActiveToggleNav(3);
                //     }
                // } else {
                //     //this.storeGeneral.setActiveShop('SHOP_SB') 
                // }
            }

            componentDidUpdate = async() => {
                let activeShop;

                if (this.storeUi.checkFeature('SHOP_ALL')) {
                    activeShop = await this.storeGeneral.getActiveShop();

                    if (this.storeGeneral.activeShop === 'SHOP_SB') {
                        if (this.props.location.pathname === '/our-publications' ||
                        this.props.location.pathname === '/our-publications/tips' ||
                        this.props.location.pathname === '/our-publications/own_book' ||
                        this.props.location.pathname === '/our-publications/our_books') {
                            this.storeUi.setActiveToggleNav(1);
                        } else {
                            if (this.storeUi.active_togglenav == 0) {
                                this.storeUi.setActiveToggleNav(2);
                            }
                        }
                    } else {
                        this.storeUi.setActiveToggleNav(3);
                    }
                } else {
                    this.storeGeneral.setActiveShop('SHOP_SB');
                }    
            }

            initReactGA = () => {
                ReactGA.initialize(this.state.configs);
                ReactGA.pageview('/start');
                this.setState({ 
                    reactGaInitialised: true 
                });
            }

            gotoCart = async() => {
                const userId = (this.storeUser.user_data.is_loggedin) ? this.storeUser.user_data.user_id : 0;
                let totalCart = await this.storeOrder.constructShoppingCart(userId, 'add');
                (async() => await this.storeOrder.saveShoppingCartToDB(userId, totalCart))();
                const path = '/shoppingcart';
                this.storeUi.setUseCase("inshop");
                this.props.history.push(path);
                this.storeUi.closeDialog();
            }

            clearLocalStorage = () => {
                localStorage.removeItem('SB_ORDER');
                localStorage.removeItem('APP_USER');
            }

            setWrapperRef = (node) => {
                this.storeUi.setWrapperRef(node);
            }

            render() {
                //const margin_bottom = this.storeUi.app_interface.height_footer;
                const margin_bottom = 0;

                const loader_colors = [
                    this.storeGeneral.settings_list["loaderColor1"],
                    this.storeGeneral.settings_list["loaderColor2"],
                    this.storeGeneral.settings_list["loaderColor3"]
                ]

                const shop = this.storeGeneral.shop;

                return (
                    <React.Fragment>
                        <CacheBuster>
                            {({ loading, isLatestVersion, refreshCacheAndReload }) => {
                                if (loading) return null;
                                if (!loading && !isLatestVersion) {
                                    refreshCacheAndReload();
                                    this.clearLocalStorage();
                                }

                                return (                           
                                    <React.Fragment>
                                        <div 
                                            ref={(c) => this.setWrapperRef(c)}
                                            className={"app-content " + (rdd.isMobile ? "app-content--mobile" : "app-content--desktop") + ' app-content--' + shop}
                                        >
                                            <Header 
                                                shop = {this.storeGeneral.shop} 
                                                access_level = {this.storeAuth.user.is_loggedin ? this.storeAuth.user.access_level : 0}
                                            />

                                            <div 
                                                className="wrapper-main"
                                                style={{marginBottom: margin_bottom}}
                                            >
                                                <Routes />
                                            </div>

                                            <Footer />
                                        </div>
                                    </React.Fragment>

                                )
                            }}
                        </CacheBuster>
                        
                        <DialogCookies 
                            visible = {this.state.cookie_visible}
                        />

                        {this.storeUi.app_interface.isDialogOpen &&
                            <DialogConfirmation
                                show = {this.storeUi.app_interface.isDialogOpen}
                                onClose = {() => this.storeUi.closeDialog()}
                                title = {i18n.t("popups.added.title")}
                                subtitle = "Het aantal kun je aanpassen in je winkelmandje."
                                sku = {this.storeOrder.lastAddedBook.sku}
                                buttons = {
                                    [{
                                        text: "Naar winkelwagentje",
                                        onClick: () => this.gotoCart(),
                                        btnClass: "--primary"
                                    },{
                                        text: "Sluit dit venster en winkel verder",
                                        onClick: () => this.storeUi.closeDialog(),
                                        btnClass: "--primary"
                                    }]
                                }
                            >
                            </DialogConfirmation>
                        }

                        {this.storeUi.app_interface.isDialogWishListOpen &&
                            <DialogConfirmationWishList
                                show = {this.storeUi.app_interface.isDialogWishListOpen}
                                onClose = {() => this.storeUi.closeDialogWishList()}
                                title = {i18n.t("popups.added.title-wishlist")}
                                sku = {this.storeWishlist.lastAddedBook.sku}
                                buttons = {
                                    [{
                                        text: "Sluit dit venster en winkel verder",
                                        onClick: () => this.storeUi.closeDialogWishList(),
                                        btnClass: "--primary"
                                    }]
                                }
                                fade = {this.storeUi.app_interface.isDialogFade}
                            >
                            </DialogConfirmationWishList>
                        }

                        {this.storeUi.app_interface.isDialogDeleteOpen &&
                            <DialogConfirmation
                                show = {this.storeUi.app_interface.isDialogDeleteOpen}
                                onClose = {() => (
                                    this.storeUi.reloadOrderLines(true),
                                    this.storeUi.closeDialogDelete()
                                )}
                                title = {i18n.t("popups.delete.title")}
                                sku = {this.storeUi.active_product.sku}
                                buttons = {
                                    [{
                                        text: "Annuleer",
                                        onClick: () => (
                                            this.storeUi.reloadOrderLines(true),
                                            this.storeUi.closeDialogDelete()
                                        ),
                                        btnClass: "--secundary"
                                    },{
                                        text: "Verwijder",
                                        onClick: async() => (
                                            this.storeOrder.submitDeleteOrderLine(
                                                (this.storeUser.user_data.is_loggedin) ? this.storeUser.user_data.user_id : 0,
                                                this.storeUi.active_product
                                            ),
                                            await (async() => this.storeShipping.calculateShipping(this.storeOrder.order_data.order_id) )(),
                                            this.storeOrder.calculateTotalCart((this.storeUser.user_data.is_loggedin) ? true : false),
                                            this.storeUi.closeDialogDelete()
                                        ),
                                        btnClass: "--primary"
                                    }]
                                }
                                >
                                <p>{i18n.t("popups.delete.description")}</p>
                            </DialogConfirmation>
                        }

                        {this.storeOrder.order_interface.payment_window &&
                            <DialogPayment
                                show = {this.storeOrder.order_interface.payment_window}
                                onClose = {() => this.storeOrder.closePaymentWindow()}
                                title = {i18n.t("popups.payment.title")}
                                subtitle = {i18n.t("popups.payment.subtitle")}
                                // buttons = {
                                //     [{
                                //         text: "Annuleer",
                                //         onClick: () => {this.storeOrder.closePaymentWindow()},
                                //         btnClass: "--primary"
                                //     }]
                                // }
                                >
                            </DialogPayment>
                        }

                        {this.storeOrder.order_interface.error_window &&
                            <DialogError
                                show = {this.storeOrder.order_interface.error_window}
                                onClose = {() => this.storeOrder.closePaymentWindow()}
                                title = {i18n.t("popups.payment.title")}
                                subtitle = {i18n.t("popups.payment.subtitle")}
                                buttons = {
                                    [{
                                        text: "Ok",
                                        onClick: () => {this.storeOrder.closeErrorWindow()},
                                        btnClass: "--primary"
                                    }]
                                }
                                >
                            </DialogError>
                        }

                        {this.storeUi.app_interface.isDialogNewsletterOpen &&
                            <DialogNewsletter
                                show = {this.storeUi.app_interface.isDialogNewsletterOpen}
                                onClose = {() => this.storeUi.closeDialogNewsletter()}
                                subtitle = {i18n.t("popups.newsletter.subtitle")}
                                >
                            </DialogNewsletter>
                        }

                        {this.storeUi.app_interface.isDialogContentOpen &&
                            <DialogContent
                                show = {this.storeUi.app_interface.isDialogContentOpen}
                                onClose = {() => this.storeUi.closeDialogContent()}
                                title = {this.storeUi.content_component_title}
                                buttons = {
                                    [{
                                        text: "Sluit venster",
                                        onClick: () => {this.storeUi.closeDialogContent()},
                                        btnClass: "--primary"
                                    }]
                                }
                                
                                >
                                    {this.storeUi.content_component}
                            </DialogContent>
                        }

                        {this.storeUi.app_interface.isDialogVideoOpen &&
                            <DialogVideo
                                show = {this.storeUi.app_interface.isDialogVideoOpen}
                                onClose = {() => this.storeUi.closeDialogVideo()}
                                title = {this.storeUi.content_component_title}
                                buttons = {
                                    [{
                                        text: "Sluit venster",
                                        onClick: () => {this.storeUi.closeDialogVideo()},
                                        btnClass: "--primary"
                                    }]
                                }
                                
                                >
                                    {this.storeUi.content_component}
                            </DialogVideo>
                        }

                        {this.storeUi.app_interface.isDialogNotificationOpen &&
                            <DialogNotification
                                show = {this.storeUi.app_interface.isDialogNotificationOpen}
                                onClose = {() => this.storeUi.closeDialog()}
                                content = {this.storeUi.notifications}
                                buttons = {
                                    [{
                                        text: "Ok",
                                        onClick: () => {this.storeUi.closeDialogNotifications()},
                                        btnClass: "--primary"
                                    }]
                                }
                            >
                            </DialogNotification>
                        }

                        {this.storeWishlist.isDialogCompareWishlistsOpen &&
                            <DialogCompareWishLists
                                show = {this.storeWishlist.isDialogCompareWishlistsOpen}
                                title = {"Verlanglijstje"}
                                onClose = {() => this.storeWishlist.compareWishLists(this.storeUser.user_data.is_loggedin ? this.storeUser.user_data.user_id : 0, 'keep')}
                                content = {"Wil je deze verlanglijstjes samenvoegen?"}
                                buttons = {
                                    [{
                                        text: "Ok!",
                                        onClick: () => {this.storeWishlist.compareWishLists(this.storeUser.user_data.is_loggedin ? this.storeUser.user_data.user_id : 0, 'concat')},
                                        btnClass: "--primary",
                                        action: "concat"
                                    }]
                                }
                            >
                            </DialogCompareWishLists>
                        }

                        {this.storeOrder.order_interface.isDialogCompareCartsOpen &&
                            <DialogCompareShoppingCarts
                                show = {this.storeOrder.order_interface.isDialogCompareCartsOpen}
                                title = {"Winkelmandje"}
                                onClose = {() => this.storeOrder.closeDialogCompareCarts()}
                                content = {"Kies welke producten je wilt behouden in je mandje:"}
                                cart_current = {this.storeOrder.cart.anonymous}
                                cart_db = {this.storeOrder.shopping_cart_db}
                                buttons = {
                                    [{
                                        text: "Ok!",
                                        onClick: () => {this.storeOrder.concatLSandDB()},
                                        btnClass: "--primary",
                                        action: "concat"
                                    }]
                                }
                            >
                            </DialogCompareShoppingCarts>
                        }

                        {this.storeUi.app_interface.isDialogFeedbackFormOpen &&
                            <DialogFeedbackForm
                                type = {this.storeUi.app_interface.dialog_type}
                                show = {this.storeUi.app_interface.isDialogFeedbackFormOpen}
                                onClose = {() => this.storeUi.closeDialogFeedbackForm()}
                            />
                        }

                        {this.storeForms.isDialogFeedbackFormOpen &&
                            <DialogFeedbackForm20
                                type = {this.storeForms.dialog_type}
                                show = {this.storeForms.isDialogFeedbackFormOpen}
                                onClose = {() => this.storeForms.closeDialogFeedbackForm()}
                            />
                        }

                        {(this.storeGeneral.app_interface.loader) &&
                            <Loader 
                                colors = {loader_colors}
                            />
                        }
                    </React.Fragment>
                );
            }
        }
    )
)

export default withCookies(withRouter(App));