import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { Redirect } from 'react-router';
import { withRouter } from "react-router-dom";
import i18n from "i18next";
import { Link } from 'react-router-dom';
import moment from "moment";

const BookAuthor = inject("stores") (
    observer (
        class BookAuthor extends Component {
            constructor(props) {
                super(props);
                this.props = props;
                this.storeUi = this.props.stores.storeUi;
                this.storeUser = this.props.stores.storeUser;
                this.storeBooks = this.props.stores.storeBooks;

                this.state = {
                }
            }

            componentDidMount = async() => {
                let shop;
                let count;

                if (this.storeUi.checkFeature('SHOP_ALL')) {
                    shop = 'SHOP_ALL';
                } else {
                    shop = 'SHOP_SB';
                }

                const str = this.props.author_firstname + ' ' + this.props.author_lastname;

                if (!this.storeUi.search_overlay) {
                    if (!this.props.is_author_page) {
                        count = await this.storeUi.returnBookCountForAuthorSimple(shop, str);
                    } else {
                        count = await this.storeUi.getBookCountForAuthor('SHOP_ALL', str);
                    }
                }
            
                this.setState({
                    count: count
                })
            }

            componentDidUpdate = async(prevProps) => {
                let count;

                if (prevProps.author_firstname !== this.props.author_firstname) {
                    let shop;
                    if (this.storeUi.checkFeature('SHOP_ALL')) {
                        shop = 'SHOP_ALL';
                    } else {
                        shop = 'SHOP_SB';
                    }
                    
                    const str = this.props.author_firstname + ' ' + this.props.author_lastname;

                    if (!this.storeUi.search_overlay) {
                        if (!this.props.is_author_page) {
                            count = await this.storeUi.returnBookCountForAuthorSimple(shop, str);
                        } else {
                            count = await this.storeUi.getBookCountForAuthor('SHOP_ALL', str);
                        }
                    }
                    
                    this.setState({
                        count: count
                    })
                }
            }

            constructAuthor = () => {
                let author = '';
                
                author = (this.props.author_firstname && this.props.author_firstname !== null) 
                    ? (this.props.author_firstname + ' ') 
                    : ''
                ;
                if (this.props.author_prefix && this.props.author_prefix !== null) {
                    author = author + this.props.author_prefix + ' ';
                };
                if (this.props.author_lastname && this.props.author_lastname !== null) {
                    author = author + this.props.author_lastname; 
                };

                return author;
            }

            handleOnClickAuthor = (firstname, lastname) => {
                let author = [];
                let author_id = 0;
                let id = firstname + ' ' + lastname;

                this.storeUi.setPageView('page', 1);
                
                if (author.length > 0) {
                    author_id = author[0].id;
                    this.storeUi.setCurrentAuthor(author_id);

                    if (this.storeUi.checkFeature('SHOP_ALL')) { 
                        this.storeBooks.getBooksForAuthor(this.storeUi.page_view, author_id, firstname, lastname, 'SHOP_ALL');
                    } else {
                        this.storeBooks.getBooksForAuthor(this.storeUi.page_view, author_id, firstname, lastname, 'SHOP_SB');
                    }
                    const url = "/author/" + author_id; 
                    this.props.pushHistory(url);
                } else {
                    author = firstname + " " + lastname;
                    this.storeUi.setCurrentAuthor(author);

                    if (this.storeUi.checkFeature('SHOP_ALL')) { 
                        this.storeBooks.getBooksForAuthor(this.storeUi.page_view, author, firstname, lastname, 'SHOP_ALL');
                    } else {
                        this.storeBooks.getBooksForAuthor(this.storeUi.page_view, author, firstname, lastname, 'SHOP_SB');
                    }
                    const url = "/author/" + this.constructAuthor(); 
                    this.props.pushHistory(url);
                }
            }

            checkCurrentAuthor = () => {
                const authorPage = this.props.author;
                const author = this.props.author_lastname;

                const thisAuthorPage = (authorPage !== undefined && authorPage.includes(author)) ? true : false;

                return thisAuthorPage;
            }
        
            render() {
                return (
                    <React.Fragment>
                        {(this.state.count > 1 && this.checkCurrentAuthor() === false)
                        ?   <React.Fragment>
                                {(this.props.str_conn === "of" || this.props.str_conn === "en") &&
                                    <span className="--connector">
                                        {this.props.str_conn}
                                    </span>
                                }
                                <span className="--link notranslate" 
                                    onClick={(e) => this.handleOnClickAuthor(this.props.author_firstname, this.props.author_lastname)}
                                    dangerouslySetInnerHTML={{ __html: this.constructAuthor()}}
                                />
                            </React.Fragment>
                            
                        :   this.props.str_conn === "en"
                            ?   <React.Fragment>
                                    <span
                                        className="--nolink --connector"
                                    >
                                        {this.props.str_conn}
                                    </span>
                                    <span 
                                        className="--nolink notranslate"
                                        dangerouslySetInnerHTML={{ __html: this.constructAuthor()}}
                                    />
                                </React.Fragment>
                            :   this.props.str_conn !== "of" &&
                                    <span 
                                        className="--nolink notranslate"
                                        dangerouslySetInnerHTML={{ __html: this.constructAuthor()}}
                                    />
                        }
                    </React.Fragment>
                )
            }
        }
    )
)

export default withRouter(BookAuthor);

