import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { withTranslation } from 'react-i18next';

import TemplateSidebarRight from '../../templates/template-sidebar-right';
import BlockGoogleTranslate from './block-google-translate';

const BlockTranslations = inject("stores") (
    observer (
        class BlockTranslations extends Component {

            constructor(props) {
                super(props);
                this.props = props;

                this.storeUi = this.props.stores.storeUi;
            }
 
            render() {
                return (
                    <TemplateSidebarRight
                        class_modifier = "translations"
                        block_title = {i18n.t("block.translations.header")}
                    >
                        {/* <div className="block__block-subtitle">
                            {i18n.t("block.translations.text")}
                        </div>  */}
                        <div className="block__block-content">
                            <BlockGoogleTranslate

                            />
                        </div>
                    </TemplateSidebarRight>
                )
            }
        }
    )
)

export default withTranslation()(BlockTranslations);


