import React, { Component } from 'react';

// Prevent page scroll when interacting with the dropdown
const handlePreventScroll = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };
  
class BlockGoogleTranslate extends Component {
    componentDidMount() {
        // Load the Google Translate script
        const script = document.createElement('script');
        script.src = '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit';
        script.async = true;
        document.body.appendChild(script);

        // Define the callback function that will initialize the widget
        if (!window.googleTranslateElementInit) {
            window.googleTranslateElementInit = () => {
                new window.google.translate.TranslateElement(
                {
                    pageLanguage: '',
                    includedLanguages: 'nl,en,de,fr,es',
                    layout: window.google.translate.TranslateElement.InlineLayout.SIMPLE,
                    autoDisplay: false,
                },
                'google_translate_element'
                );

                const translateDropdown = document.querySelector('.goog-te-gadget');
                if (translateDropdown) {
                    translateDropdown.addEventListener('click', (e) => {
                        e.preventDefault();
                        e.stopPropagation();
                    });
                }

                this.setupLanguageChangeObserver();

            };
        }        
    }

    setupLanguageChangeObserver = () => {
        const observer = new MutationObserver((mutations) => {
            mutations.forEach((mutation) => {
                if (mutation.type !== 'attributes') {
                    window.scrollTo(0, 0);
                }
            });
        });
    
        // Observe the Google Translate widget container
        const widgetContainer = document.querySelector('#google_translate_element');
        if (widgetContainer) {
            observer.observe(widgetContainer, {
                attributes: true, // Monitor attribute changes
                childList: true, // Monitor child elements
                subtree: true, // Monitor all descendants
            });
        }
    }

    render() {
        return (
            <div>
                <div id="google_translate_element" />
            </div>
        );
    }
}

export default BlockGoogleTranslate;


